import _ from 'lodash';
import {
  ShoppingCart as ProductsIcon,
  FileText as DocsIcon,
  BarChart as BarChartIcon,
  MessageSquare as TicketsIcon,
  Users as UsersIcon,
  Gift as PromoCodesIcon,
  Globe as LanguagesIcon,
  FileMinus as LogsIcon,
  Image as MediaIcon,
  PieChart as PieChartIcon,
  Package as PackageIcon,
  Activity as ActivityIcon,
  CreditCard as TransactionIcon,
  MessageCircle as FeedbackIcon,
  PhoneCall as ContactPageIcon,
  Briefcase as CareerPageIcon,
} from 'react-feather';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const GAME = {
  name: 'Sevimli Dostlar',
};

export const COMPANY = {
  emailCheck: {
    regex: /^[A-Za-z0-9._%+-]+@(adisebaba.com|diyojen.io)$/,
    text: 'Only authorized emails',
  },
};

export const platformOptions = [
  {
    value: 'playstore',
    text: 'PlayStore',
  },
  {
    value: 'appstore',
    text: 'AppStore',
  },
];

export const getPlatformLabel = platform => {
  return _.find(platformOptions, { value: platform }).text;
};

export const SECTIONS = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/overview/dashboard',
      },
      {
        title: 'Activities',
        icon: ActivityIcon,
        href: '/app/overview/activities',
      },
      {
        title: 'Transactions (AppsFlyer)',
        icon: TransactionIcon,
        href: '/app/overview/transactions',
      },
      {
        title: 'Charts',
        icon: PieChartIcon,
        href: '/app/overview/charts',
        items: [
          {
            title: 'Revenue',
            href: '/app/overview/charts/revenue',
          },
          {
            title: 'Monthly Recurring Revenue',
            href: '/app/overview/charts/mrr',
          },
          {
            title: 'New Trials',
            href: '/app/overview/charts/new-trials',
          },
          {
            title: 'Active Subscriber Base',
            href: '/app/overview/charts/active-subscriber-base',
          },
          {
            title: 'Trial Start Conversion',
            href: '/app/overview/charts/trial-start-conversion',
          },
          {
            title: 'Trial to Paid Conversion',
            href: '/app/overview/charts/trial-to-paid-conversion',
          },
          {
            title: 'Subscription Conversion',
            href: '/app/overview/charts/subscription-conversion',
          },
          {
            title: 'Churn',
            href: '/app/overview/charts/churn',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/management/users',
      },
      {
        title: 'Product Packages',
        icon: PackageIcon,
        href: '/app/management/product-packages',
      },
      {
        title: 'Products',
        icon: ProductsIcon,
        href: '/app/management/products',
      },
      {
        title: 'Promo Codes',
        icon: PromoCodesIcon,
        href: '/app/management/promo-codes',
      },
      {
        title: 'Languages',
        icon: LanguagesIcon,
        href: '/app/management/languages',
      },
      {
        title: 'Media',
        href: '/app/management/media',
        icon: MediaIcon,
      },
    ],
  },
  {
    subheader: 'Application',
    items: [
      {
        title: 'Tickets',
        href: '/app/tickets',
        icon: TicketsIcon,
      },
      {
        title: 'Logs',
        href: '/app/logs',
        icon: LogsIcon,
      },
    ],
  },
  {
    subheader: 'Forms',
    items: [
      {
        title: 'Feedback',
        icon: FeedbackIcon,
        href: '/app/forms/feedback',
      },
      {
        title: 'Contact Page',
        icon: ContactPageIcon,
        href: '/app/forms/contact-page',
      },
      {
        title: 'Career Page',
        icon: CareerPageIcon,
        href: '/app/forms/career-page',
      },
    ],
  },
  {
    subheader: 'Developer',
    items: [
      {
        title: 'API docs',
        icon: DocsIcon,
        href: '/docs',
      },
    ],
  },
];
